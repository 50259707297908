import React, { useState, useEffect, useRef } from "react";
import I18n from "i18n-js";
import { Label } from 'reactstrap';

import BulkNotificationSignSignerList from "../../../../bulk/notification/sign/signer_list";

import { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from "react-bootstrap-table2-paginator";
import { options as optionPagination } from "../../../../helper/pagination";

import { index as indexCorporateSigner } from "../../../../corporate/signer/axios";
import { limitCharacters } from "../../../../helper/form";

import BootstrapTableFilterHeader from "../../../../helper/bootstrap-table/filter/filter_header";
import BootstrapTableSortHeader from "../../../../helper/bootstrap-table/filter/sort_header";
import useFilterrific from "../../../../hooks/useFilterrific";

import useFilterRequestParams from "../../../../hooks/useFilterRequestParams";
import { defaultTableHeaderStyleWhite } from "../../../../helper/bootstrap-table/helper";

const CorporateBulkNotificationSignForm = props => {
  const [signers, setSigners] = useState([]);
  const [selectedSigners, setSelectedSigners] = useState([]);

  const documentTypes = props?.documentTypes;
  const formName = "corporate_bulk_notification_sign";

  const [requestFilterParams, setRequestFilterParams] = useFilterRequestParams({});

  const bootstrapTable = useRef(null);

  // Pagination
  const [totalSize, setTotalSize] = useState(signers?.length || 10);
  const [filterrific, setFilterrific] = useFilterrific({ ...filterrific });

  useEffect(() => {
    getSigners();
  }, [requestFilterParams]);

  const getSigners = () => {
    let _requestFilterParams = { ...requestFilterParams }
    requestFilterParams['filterrific']['by_aasm_state'] = 'pending'

    indexCorporateSigner(_requestFilterParams, response => {
      setSigners(response.data.signers);
      setTotalSize(response.data.total);
    });
  };

  const columns = [
    {
      dataField: "document.entity.agreement_config.client.name",
      text: `${ I18n.t("activerecord.attributes.corporate/agreement.company_client_name") }`,
      filterByFilterrific: "search_by_company_client",
      headerStyle: defaultTableHeaderStyleWhite,
      filter: textFilter({
        placeholder: `Escriba un ${ I18n.t("activerecord.attributes.corporate/company.name") }`
      }),
      formatter: (value,row,index) => value || I18n.t('no_entry'),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document.entity.agreement_config.provider.name",
      text: `${ I18n.t("activerecord.attributes.corporate/agreement.company_provider_name") }`,
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value,row,index) => value || I18n.t('no_entry'),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "email",
      text: `${ I18n.t("activerecord.attributes.signer.email") }`,
      filterByFilterrific: "search_by_email",
      sortByFilterrific: "email_",
      headerStyle: defaultTableHeaderStyleWhite,
      filter: textFilter({
        placeholder: `Escriba un ${ I18n.t("activerecord.attributes.signer.email") }`
      }),
      sort: true,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value,row,index) => value || I18n.t('no_entry'),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "name",
      text: `${ I18n.t("activerecord.attributes.signer.name") }`,
      filterByFilterrific: "search_by_name",
      sortByFilterrific: "name_",
      headerStyle: defaultTableHeaderStyleWhite,
      filter: textFilter({
        placeholder: `Escriba un ${ I18n.t("activerecord.attributes.signer.name") }`
      }),
      sort: true,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value,row,index) => value || I18n.t('no_entry'),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "role",
      text: `${ I18n.t("activerecord.attributes.signer.role") }`,
      filterByFilterrific: "search_by_role",
      filter: textFilter({
        placeholder: `Ingrese un ${ I18n.t("activerecord.attributes.signer.role") }`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document.entity.identifier",
      text: `${I18n.t("activerecord.attributes.corporate/agreement.identifier")}`,
      filterByFilterrific: "search_by_agreement_identifier",
      filter: textFilter({
        placeholder: `Ingrese el identificador de la ${I18n.t("activerecord.attributes.corporate/agreement")}`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document.entity.active_to_s",
      text: `Estado del Contrato`,
      filterByFilterrific: "by_agreement_state",
      filter: selectFilter({
        placeholder: "Seleccione un estado",
        options: [
          { value: 'active', label: "Activo" },
          { value: 'expired', label: "Inactivo" },
        ],
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document.entity.start_date_to_s",
      text: `${I18n.t("activerecord.attributes.corporate/agreement.start_date")}`,
      filterByFilterrific: "search_by_agreement_start_date",
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t("activerecord.attributes.corporate/agreement.start_date")}`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document.entity.end_date_to_s",
      text: `${I18n.t("activerecord.attributes.corporate/agreement.end_date")}`,
      filterByFilterrific: "search_by_agreement_end_date",
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t("activerecord.attributes.corporate/agreement.end_date")}`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document.label",
      text: `${I18n.t("activerecord.attributes.document.label")}`,
      filterByFilterrific: "search_by_document_name",
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t("activerecord.attributes.document.label")}`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document_type.name",
      text: `${ I18n.t("activerecord.attributes.document.document_type") }`,
      filterByFilterrific: "by_document_type",
      filter: selectFilter({
        placeholder: `Seleccione un ${ I18n.t("activerecord.attributes.document.document_type") }`,
        options: _.map(documentTypes, type => ({
          value: type.id,
          label: type.name,
        })),
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value,row,index) => value || I18n.t('no_entry'),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "created_at_to_s",
      text: `${I18n.t("activerecord.attributes.signer.created_at")}`,
      sortByFilterrific: "created_at_",
      filterByFilterrific: "search_by_created_at",
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t("activerecord.attributes.signer.created_at")}`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
  ];

  const getCustomFields = customFields => {
    return _.map(customFields, function (custom) {
      return {
        dataField: `document.entity.custom_fields.${ custom.code }`,
        text: _.capitalize(custom.label),
        filterByFilterrific: `corporate.custom_fields.${ custom.code }`,
        filter: textFilter({
          placeholder: `Escriba un ${ custom.code }`
        }),
        headerStyle: defaultTableHeaderStyleWhite,
        formatter: (value, row, index) => { return limitCharacters(value) },
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable }}
          />
        ),
      };
    });
  };

  const signerColumns = _.concat(
    columns,
    getCustomFields(props?.customFields)
  );

  const handleSigners = (_signers) => {
    setSelectedSigners(_signers)
  }

  const handleTableChange = (type, args) => {
    setRequestFilterParams({
      type: type,
      args: args,
      bootstrapTable: bootstrapTable
    })
  };

  const drawSelectedSignersInput = () => {
    return(
      <>
        { _.map(selectedSigners, function(signer){
          return(
            <div key={ `signer-input-${ signer.id }` }>
              <input
                type="hidden"
                name={ `${ formName }[items_attributes][][signer_id]` }
                value={ signer.id }
              />
            </div>
          )
        })}
      </>
    )
  }

  return (
    <div className="row">
      <div className="col-12">
        <Label className="d-flex justify-content-center h5 my-4">Lista de firmas pendientes</Label>
        <BulkNotificationSignSignerList
          signers={ signers }
          signerColumns={ signerColumns }
          selectedSigners={ selectedSigners }
          handleSigners={ handleSigners }
          pagination={ paginationFactory(optionPagination({
            totalSize: totalSize,
            sizePerPage: requestFilterParams?.per_page,
          })) }
          onTableChange={ handleTableChange }
          _ref={ bootstrapTable }
        />

        { drawSelectedSignersInput() }
      </div>
    </div>
  );
};

export default CorporateBulkNotificationSignForm;
