import React, { useState, useEffect, useRef } from "react";
import I18n from "i18n-js";
import { Label } from "reactstrap";

import BulkNotificationSignSignerList from "../../../../bulk/notification/sign/signer_list";

import {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  options as optionPagination,
} from "../../../../helper/pagination";

import { index as indexAbstractSigners } from "../../../../abstract/signer/axios";
import { limitCharacters } from "../../../../helper/form";

import BootstrapTableFilterHeader from "../../../../helper/bootstrap-table/filter/filter_header";
import useFilterrific from "../../../../hooks/useFilterrific";
import { defaultTableHeaderStyleWhite } from "../../../../helper/bootstrap-table/helper";

import useFilterRequestParams from "../../../../hooks/useFilterRequestParams";

const AbstractBulkNotificationSignForm = props => {
  const formName = "abstract_bulk_notification_sign";
  const [signers, setSigners] = useState([]);
  const {
    documentTypes,
    currentEntityType,
    customFields
  } = props

  const [selectedSigners, setSelectedSigners] = useState([]);
  const [totalSize, setTotalSize] = useState(signers?.length || 10);

  const [filterrific, setFilterrific] = useFilterrific({...filterrific});
  const [requestFilterParams, setRequestFilterParams] = useFilterRequestParams({});

  const bootstrapTable = useRef(null);

  useEffect(() => {
    getSigners();
  }, [requestFilterParams]);


  const getSigners = () => {
    let _requestFilterParams = {
      ...requestFilterParams,
      by_aasm_state: 'pending',
      current_entity_type_id: currentEntityType.id
    }

    indexAbstractSigners(_requestFilterParams, response => {
      setSigners(response.data.signers);
      setTotalSize(response.data.total);
    });
  };

  const columns = [
    {
      dataField: "email",
      text: `${I18n.t("activerecord.attributes.signer.email")} firmante`,
      filterByFilterrific: "search_by_email",
      sortByFilterrific: "email_",
      filter: textFilter({
        placeholder: `Escriba un ${I18n.t("activerecord.attributes.signer.email")}`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "name",
      text: `${I18n.t("activerecord.attributes.signer.name")} firmante`,
      filterByFilterrific: "search_by_name",
      sortByFilterrific: "name_",
      filter: textFilter({
        placeholder: `Ingrese un ${I18n.t("activerecord.attributes.signer.name")}`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "role",
      text: `${ I18n.t("activerecord.attributes.signer.role") }`,
      filterByFilterrific: "search_by_role",
      filter: textFilter({
        placeholder: `Ingrese un ${ I18n.t("activerecord.attributes.signer.role") }`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document.label",
      text: `${I18n.t("activerecord.attributes.document.label")}`,
      filterByFilterrific: "search_by_document_name",
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t("activerecord.attributes.document.label")}`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document_type.name",
      text: `${I18n.t("activerecord.attributes.document.document_type")}`,
      filterByFilterrific: "by_document_type",
      filter: selectFilter({
        placeholder: `Seleccione un ${I18n.t("activerecord.attributes.document.document_type")}`,
        options: _.map(documentTypes, type => ({
          value: type.id,
          label: type.name,
        })),
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "created_at_to_s",
      text: `${I18n.t("activerecord.attributes.signer.created_at")}`,
      sortByFilterrific: "created_at_",
      filterByFilterrific: "search_by_created_at",
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t("activerecord.attributes.signer.created_at")}`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
  ];

  const getCustomFields = customFields => {
    return _.map(customFields, function (custom) {
      return {
        dataField: `document.entity.custom_fields.${custom.code}`,
        text: _.capitalize(custom.label),
        filterByFilterrific: `abstract.custom_fields.${ custom.code }`,
        myFilter: `custom_fields.${ custom.code }`,
        filter: textFilter({}),
        headerStyle: defaultTableHeaderStyleWhite,
        formatter: (value, row, index) => { return limitCharacters(value) },
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable }}
          />
        ),
      };
    });
  };

  const signerColumns = _.concat(
    columns,
    getCustomFields(customFields)
  );

  const handleSigners = _signers => {
    setSelectedSigners(_signers);
  };

  const handleTableChange = (type, args) => {
    setRequestFilterParams({
      type: type,
      args: args,
      bootstrapTable: bootstrapTable
    })
  };

  const drawSelectedSignersInput = () => {
    return (
      <>
        { _.map(selectedSigners, function (signer) {
          return (
            <div key={ `signer-input-${signer.id}` }>
              <input
                type="hidden"
                name={ `${formName}[items_attributes][][signer_id]` }
                value={ signer.id }
              />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="row">
      <div className="col-12">
        <Label className="d-flex justify-content-center h5 my-4">
          Lista de firmas pendientes
        </Label>
        <BulkNotificationSignSignerList
          signers={ signers }
          signerColumns={ signerColumns }
          selectedSigners={ selectedSigners }
          handleSigners={ handleSigners }
          pagination={ paginationFactory(optionPagination({
            totalSize: totalSize,
            sizePerPage: requestFilterParams?.per_page,
          })) }
          onTableChange={ handleTableChange }
          _ref={ bootstrapTable }
        />

        { drawSelectedSignersInput() }
      </div>
    </div>
  );
};

export default AbstractBulkNotificationSignForm;
