import React, { useState, useRef } from 'react';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { pageListRenderer, options as optionPagination } from "../../helper/pagination";
import LaboralBulkMyFilterForm from "../../laboral/bulk/my_filter/form";
import LaboralBulkMyFilterList from "../../laboral/bulk/my_filter/list";
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import I18n from 'i18n-js/index.js.erb'

const BootstrapTableCustomFilter = (props) => {
  const { ExportCSVButton } = CSVExport;

  let {
    showBulkMyFilter,
    remote,
    keyField,
    selectRow,
    data,
    filter,
    columns,
    pagination,
    classes,
    onTableChange,
    selectedRows,
    _ref,
    configuration
  } = props;

  const [showSearchFilters, setShowSearchFilters] = useState(false)
  const [myFilterCreated, setMyFilterCreated] = useState(false)

  const handleShowSearchFilters = (e) => {
    setShowSearchFilters(!showSearchFilters)
  }

  const drawBulkMyFilter = () => {
    if(showBulkMyFilter){
      return(
        <>
          <LaboralBulkMyFilterList
            bootstrapTableRef={_ref}
            myFilterCreated={ myFilterCreated }
            setMyFilterCreated={ setMyFilterCreated }
          />

          <LaboralBulkMyFilterForm
            bootstrapTableRef={_ref}
            setMyFilterCreated={ setMyFilterCreated }
          />
        </>
      )
    }
  }

  const exportToCsvButton = (csvProps) => {
    if(configuration?.isExportedCsv && selectedRows?.length > 0){
      const handleClick = () => {
        csvProps.onExport(selectedRows);
      };
      return (
        <div>
          <a className="btn btn-outline-primary mr-2" onClick={ handleClick }>{ `${I18n.t('actions.download')} excel`}</a>
        </div>
      )
    }
  };

  return(
    <ToolkitProvider
      keyField="id"
      data={data}
      columns={columns}
      exportCSV={ {
        onlyExportSelection: true,
        exportAll: true,
        fileName: `${configuration?.filenameCsv}.xlsx`,
      }}
    >
      {
        toolkitProps => (
          <div className="row">
            <div className="d-flex d-flex justify-content-between">
              { drawBulkMyFilter() }
              <div className="d-flex">
                { exportToCsvButton(toolkitProps.csvProps) }
                <button
                  type='button'
                  onClick={ e => handleShowSearchFilters(e) }
                  className='btn btn-success mb-2'
                >
                  { showSearchFilters ? 'Ocultar filtros' : 'Mostrar filtros'}
                </button>
              </div>
            </div>
            <div className="col-12">
              <BootstrapTable
                { ... {remote,
                  keyField,
                  selectRow,
                  filter,
                  pagination,
                  classes,
                  onTableChange
                  }
                }
                {...toolkitProps.baseProps}
                ref={ _ref }
                showSearchFilters={ showSearchFilters }
                handleShowSearchFilters={ handleShowSearchFilters }
              />
            </div>
          </div>
        )
      }
    </ToolkitProvider>
  )
}


export default BootstrapTableCustomFilter;
