import React, { useState, useEffect, useRef, useMemo } from 'react';

import I18n from "i18n-js";

import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";

import BootstrapTableFilterHeader from "../../../../helper/bootstrap-table/filter/filter_header";

import BulkRequestDocumentForm from '../../../../bulk/request/document/form';

import BootstrapTableSortHeader from "../../../../helper/bootstrap-table/filter/sort_header";

import DocumentFormContext from '../../../../document/context/document_form_context';

import { limitCharacters } from "../../../../helper/form";
import { defaultTableHeaderStyleWhite } from "../../../../helper/bootstrap-table/helper";
import { index as indexLaboralHiring } from "../../../hiring/axios";
import { create as createBulkRequest } from "./axios";

const LaboralBulkRequestHiringDocumentForm = (props) => {
  const {
    bulkRequest,
    currentUser,
    currentCustomer,
    data: {
      companies,
      dateFormats,
      hiringCustomFields,
      employeeCustomFields,
      securityLayers
    }
  } = props;

  const context = "laboral_bulk";

  const actionsProps = {
    indexEntities: indexLaboralHiring,
    createBulkRequest: createBulkRequest,
    showBulkRequestPath: Routes.laboral_bulk_request_hiring_document_path,
    indexBulkRequestPath: Routes.laboral_bulk_request_hiring_documents_path
  }

  const hiringStateOptions = [ {value: 'active', label: I18n.t('laboral.hirings.index.tabs.active') }, { value: 'expired', label: I18n.t('laboral.hirings.index.tabs.expired') } ]

  const defaultRequestParams = {};

  // Start UseRef
  const bootstrapTableRef = useRef(null);

  const entityConfiguration = {
    key: 'hirings',
    form: {
      entityItemsAttributes: {
        key: 'hiring_items_attributes',
        entityId: 'hiring_id'
      },
    },
    label: {
      singular: I18n.t("laboral.bulk.request.hiring_documents.form.tabs.hiring"),
      plural: I18n.t("laboral.bulk.request.hiring_documents.form.tabs.hirings"),
    },
    navButtonTitle: I18n.t("laboral.bulk.request.hiring_documents.form.tabs.hirings_title")
  }

  const documentFormContextValue = {
    currentUser: currentUser || {},
    currentCustomer: currentCustomer || {},
    isPersisted: false,
    context: "laboral_bulk",
    data: props?.data,
    entities: [],
  }

  const entityHeaderConfigTab = useMemo(() => {
    return _.flattenDeep([
      {
        label: I18n.t('activerecord.attributes.laboral/hiring.identifier'),
        key: 'identifier'
      },{
        label: I18n.t('activerecord.attributes.laboral/hiring.company'),
        key: 'company.name'
      },{
        label: I18n.t('activerecord.attributes.laboral/hiring.employee'),
        key: 'employee.name'
      },{
        label: I18n.t('activerecord.attributes.laboral/hiring.start_date'),
        key: 'start_date_to_s'
      },{
        label: I18n.t('activerecord.attributes.laboral/hiring.end_date'),
        key: 'end_date_to_s'
      },
       _.map(hiringCustomFields, customField => {
        return {
          label: customField.label,
          key: `custom_fields.${ customField.code }`
        }
      })
    ])
  }, [])

  // Start Getter
  const tableColumnsCustomFields = () => {
    return _.map(hiringCustomFields || [], function(customField){
      return {
        dataField: `custom_fields.${ customField.code }`,
        text: _.capitalize(customField.label),
        headerStyle: {...defaultTableHeaderStyleWhite, width: "100px"},
        filterByFilterrific: `custom_fields.${ customField.code }`,
        myFilter: `hiring.custom_fields.${ customField.code }`,
        filter: textFilter({}),
        formatter: (value, row, index) => { return limitCharacters(value) },
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
          />
        ),
      };
    })
  }

  const tableColumnSecurityLayer = () => {
    if(currentCustomer.security_layer && securityLayers?.length > 0){
      return {
        dataField: `employee.security_layer.name`,
        text: I18n.t("activerecord.attributes.laboral/employee.security_layer_id"),
        headerStyle: defaultTableHeaderStyleWhite,
        filterByFilterrific: `by_employee_security_layer_id`,
        myFilter: `employee.security_layer.name`,
        filter: selectFilter({
          placeholder: `Seleccione una ${ I18n.t("activerecord.attributes.laboral/employee.security_layer_id") }`,
          options: _.map(securityLayers, securityLayer => ({
            value: securityLayer.id,
            label: securityLayer.name,
          })),
        }),
        formatter: (value, row, index) => { return limitCharacters(value) },
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
          />
        ),
      }
    } else {
      return [];
    }
  }

  const tableColumnsDefault = [
    {
      dataField: "company.name",
      text: I18n.t("activerecord.attributes.laboral/hiring.company"),
      headerStyle: defaultTableHeaderStyleWhite,
      filterByFilterrific: "by_company",
      myFilter: 'company.name',
      filter: selectFilter({
        placeholder: `Seleccione una ${ I18n.t("activerecord.attributes.laboral/hiring.company") }`,
        options: _.map(companies, company => ({
          value: company.id,
          label: company.name,
        })),
      }),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
        />
      ),
    },
    {
      dataField: "employee.identifier",
      text: I18n.t("activerecord.attributes.laboral/employee.identifier"),
      headerStyle: defaultTableHeaderStyleWhite,
      filterByFilterrific: "search_by_employee_identifier",
      myFilter: 'employee.identifier',
      filter: textFilter({
        placeholder: `Ingrese el ${ I18n.t("activerecord.attributes.laboral/employee.identifier") }`,
      }),
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
        />
      ),
    },
    {
      dataField: "employee.name",
      text: I18n.t("activerecord.attributes.laboral/hiring.employee"),
      headerStyle: defaultTableHeaderStyleWhite,
      filterByFilterrific: "search_by_name",
      sortByFilterrific: "employee_name_",
      myFilter: 'employee.name',
      filter: textFilter({
        placeholder: `Ingrese el ${ I18n.t("activerecord.attributes.laboral/hiring.employee") }`,
      }),
      sort: true,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
        />
      ),
    },
    {
      dataField: "identifier",
      text: I18n.t("activerecord.attributes.laboral/hiring.identifier"),
      headerStyle: defaultTableHeaderStyleWhite,
      filterByFilterrific: "search_query",
      myFilter: 'hiring.identifier',
      filter: textFilter({
        placeholder: `Ingrese el ${ I18n.t("activerecord.attributes.laboral/hiring.identifier") }`,
      }),
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
        />
      ),
    },
    {
      dataField: "active_to_s",
      text: `Estado de Contratación`,
      headerStyle: defaultTableHeaderStyleWhite,
      filterByFilterrific: "by_hiring_state",
      myFilter: 'hiring.active_to_s',
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      filter: selectFilter({
        placeholder: `Seleccione un estado de contratación`,
        options: hiringStateOptions,
      }),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
        />
      ),
    },
    {
      dataField: "start_date_to_s",
      text: I18n.t("activerecord.attributes.laboral/hiring.start_date"),
      headerStyle: defaultTableHeaderStyleWhite,
      sortByFilterrific: "start_date_",
      myFilter: 'hiring.start_date_to_s',
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      sort: true,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
        />
      ),
    },
    {
      dataField: "end_date_to_s",
      headerStyle: defaultTableHeaderStyleWhite,
      text: I18n.t("activerecord.attributes.laboral/hiring.end_date"),
      myFilter: 'hiring.end_date_to_s',
      formatter: (value, row, index) => value || I18n.t("undefined"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
        />
      ),
    },
  ]

  const tableColumns = _.concat(
    tableColumnSecurityLayer(),
    tableColumnsDefault,
    tableColumnsCustomFields()
  )

  return(
    <DocumentFormContext.Provider value={ documentFormContextValue }>
      <div className="row">
        <div className="col-12">
          <BulkRequestDocumentForm
            bulkRequest={ bulkRequest }
            bootstrapTableConfiguration={{
              ref: bootstrapTableRef,
              tableColumns: tableColumns
            }}
            configuration={{
              showBulkMyFilter: true,
              defaultRequestParams: defaultRequestParams,
              entity: entityConfiguration,
              entityHeaderConfigTab: entityHeaderConfigTab,
              context: context,
              formName: 'laboral_bulk_request_hiring_document',
              shortlist: {
                enabled: currentCustomer?.shortlist_integration_enabled || false
              }
            }}
            actions={
              actionsProps
            }
            data={{
              ... props?.data,
              customFields: _.concat(hiringCustomFields, employeeCustomFields)
            }}
          />
        </div>
      </div>
    </DocumentFormContext.Provider>
  )
}

export default LaboralBulkRequestHiringDocumentForm;
