import React, { useState, useEffect, useRef } from 'react';
import I18n from "i18n-js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { options as paginationOption } from "../../../helper/pagination";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import BootstrapTableCustomFilter from "../../../helper/bootstrap-table/table_custom_filter";

const BulkNotificationSignSignerList = props => {
  const signers = props?.signers;
  const signerColumns = props?.signerColumns;
  const handleSigners = props?.handleSigners;

  const [selectedSigners, setSelectedSigners ] = useState(props?.selectedSigners || []);


  useEffect(() => {
    setSelectedSigners(props?.selectedSigners);
  }, [props])


  const selectRow = {
    mode: 'checkbox',
    style: { width: '10px' },
    headerColumnStyle: {
      position: 'sticky',
      top: 0,
      backgroundColor: '#fff'
    },
    clickToSelect: true,
    onSelect: (row, isSelected) => {
      handleSelectSigner({ value: row.id, checked: isSelected })
    },
    onSelectAll: (isSelected, rows) => {
      handleSelectAllSigners(isSelected, rows);
    }
  };

  const handleSelectSigner = (e) => {
    let _selectedSigners = [...selectedSigners] ;

    if (e.checked) {
      _selectedSigners.push(
        _.find(signers, signer => signer.id === e.value)
      );
    } else if (!e.checked) {
      _.remove(_selectedSigners, signer => signer.id === e.value )
    }

    if(_.isFunction(handleSigners)){
      handleSigners(_selectedSigners)
    } else {
      setSelectedSigners(_selectedSigners);
    }
  };

  const handleSelectAllSigners = (isSelected, rows) => {
    let _selectedSigners = [...selectedSigners];

    if (isSelected) {
      _selectedSigners = _.compact(_.concat(_selectedSigners, rows))
    } else {
      let ids = _.map(rows, signer => signer.id);
      _.remove(_selectedSigners, function(signer) {
        return _.includes(ids, signer.id);
      });
    }

    if(_.isFunction(handleSigners)){
      handleSigners(_selectedSigners)
    } else {
      setSelectedSigners(_selectedSigners);
    }
  };

  const drawSignerList = () => {
    return(
      <BootstrapTableCustomFilter
        showBulkMyFilter={ props?.showBulkMyFilter || false }
        remote={ true }
        keyField="id"
        configuration={ props?.configuration }
        selectRow={ selectRow }
        selectedRows={ selectedSigners }
        data={ signers }
        filter={ filterFactory() }
        columns={ signerColumns }
        pagination={ props.pagination }
        classes='table-responsive height-600'
        onTableChange={props.onTableChange}
        _ref={props._ref}
      />
    )
  }

  return(
    <div className="row">
      <div className="col-12">
        { drawSignerList() }
      </div>
    </div>
  )
}

export default BulkNotificationSignSignerList;
